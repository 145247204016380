<script setup lang="ts">
    import { defineProps, withDefaults } from "vue";
    import { ServiceWorkerProgress } from "o365.pwa.modules.client.steps.ServiceWorkerProgress.ts";
    import ServiceWorkerProgressOffline from "o365.pwa.vue.components.steps.ServiceWorkerProgressOffline.vue";
    import ServiceWorkerProgressOnline from "o365.pwa.vue.components.steps.ServiceWorkerProgressOnline.vue";
    
    interface IProps {
        syncStepProgress: ServiceWorkerProgress;
        currentStep: boolean;
    }

    const props = withDefaults(defineProps<IProps>(), {
        currentStep: false,
    });
</script>

<script lang="ts">
    export default {
        name: "ServiceWorkerProgress",
    };
</script>

<template>
    <ServiceWorkerProgressOffline v-if="props.syncStepProgress.syncType === 'OFFLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep"/>
    <ServiceWorkerProgressOnline v-else-if="props.syncStepProgress.syncType === 'ONLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep"/>
</template>
